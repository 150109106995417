import React from 'react'
import { connect } from 'react-redux'

import {
  FileType,
  GalleryControlInterface,
  GalleryItemInterface,
  HouseAndLandPackageOptionType,
  LotGallery as LotGalleryInterface,
  PlayerControlInterface,
  ProjectIdentity,
  RootStateFirebase,
} from '@store/types'

import CarouselHandler from '@components/carousel-handler'
import PlayerControl from '@components/player-control'

import {
  FileInterface,
  GalleryInterface,
  PackageInterface,
} from '@api/houseAndLand'
import { UnitGalleryInterface } from '@api/unit-gallery'

import FirebaseControlQuery from '@utilities/firebase-control-query'

import DeselectedView from './deselected-view'

export interface LotGalleryControlSessionInterface {
  galleryControl: GalleryControlInterface
  playerControl: PlayerControlInterface
}

interface LotGalleryProps {
  lotGalleryControlSession: LotGalleryControlSessionInterface | undefined
  lotGalleryData?: Array<LotGalleryInterface>
  lastActiveOptionType?: string
  lastActiveOptionId?: string
  setShouldShowSlideShowBtn: (arg: boolean) => void
  activePackageData?: PackageInterface
  packageImages: FileInterface[]
  projectIdentity: ProjectIdentity
}

const NO_IMAGE_OBJECT = {
  id: 'no-image',
  src: '',
  label: 'No Image',
  type: FileType.Image,
  noSpliceUrl: false,
}

const LotGallery = ({
  lotGalleryControlSession,
  lotGalleryData,
  lastActiveOptionType,
  lastActiveOptionId,
  setShouldShowSlideShowBtn,
  activePackageData,
  packageImages,
  projectIdentity,
}: LotGalleryProps) => {
  const firebaseControlQuery = FirebaseControlQuery({ projectIdentity })

  const [currentVideoPlayback] = React.useState<string>('')
  const [showEnlargedView, setShowEnlargedView] = React.useState(false)

  const transformDataForGallery = (
    argData: Array<FileInterface | GalleryInterface>
  ): LotGalleryInterface[] =>
    argData
      .sort((a, b) => a.order - b.order)
      .map((item: FileInterface) => ({
        id: item.id,
        src: item.url,
        label: item.title || item.name,
        type: 'type' in item ? (item.type as FileType) : FileType.Image,
        thumbnail: 'thumbnail' in item ? (item.thumbnail as string) : '',
        noSpliceUrl: false,
      }))

  const lotGallery: LotGalleryInterface[] = React.useMemo(() => {
    if (!activePackageData) {
      const myLotGalleries: Array<LotGalleryInterface> = lotGalleryData || []
      if (myLotGalleries.length === 0) {
        return []
      }

      return transformDataForGallery(
        [...myLotGalleries].map((item: LotGalleryInterface) => ({
          id: item.id,
          name: item.label || '',
          title: item.label || '',
          url: item.src,
          type: item.type,
          thumbnail: item.thumbnail,
          order: 0,
        }))
      )
    }

    if (
      Object.values(HouseAndLandPackageOptionType).includes(
        lastActiveOptionType as HouseAndLandPackageOptionType
      )
    ) {
      if (!lastActiveOptionId) {
        return []
      }

      if (packageImages.length === 0) {
        return [NO_IMAGE_OBJECT]
      }

      return transformDataForGallery([...packageImages])
    }

    const packageGalleries = activePackageData?.galleries || []

    if (packageGalleries.length === 0) {
      return [NO_IMAGE_OBJECT]
    }

    return transformDataForGallery([...packageGalleries])
  }, [
    activePackageData,
    lastActiveOptionId,
    lastActiveOptionType,
    packageImages,
  ])

  React.useEffect(() => {
    firebaseControlQuery.update({
      [`lotGallery.galleryControl.activeItemID`]: lotGallery?.[0]?.id || 0,
    })
  }, [lotGallery?.[0]?.id])

  React.useEffect(() => {
    setShouldShowSlideShowBtn(lotGallery.length > 1)
  }, [lotGallery.length])

  const galleryData: GalleryItemInterface[] = React.useMemo(() => {
    const galleryItems: Array<GalleryItemInterface> = []

    if (lotGallery && lotGallery.length > 0) {
      lotGallery.forEach((galleryItem: UnitGalleryInterface) => {
        const imageSource =
          galleryItem.type === 'video'
            ? galleryItem.thumbnail || ''
            : galleryItem.src
        const videoSource = galleryItem.type === 'video' ? galleryItem.src : ''
        galleryItems.push({
          id: galleryItem.id,
          imageSource,
          videoSource,
          config: {
            staticUrl: true,
          },
        })
      })
    }

    return galleryItems
  }, [lotGallery])

  const enableEnlargedView = React.useMemo(
    () =>
      (galleryData || []).filter((item) => item.id !== NO_IMAGE_OBJECT.id)
        .length >= 1,
    [galleryData]
  )

  return (
    <>
      {!!lotGallery && lotGallery.length > 0 ? (
        <>
          <CarouselHandler
            carouselName="lotGallery"
            carousel={{
              label: '',
              items: galleryData,
            }}
            carouselSession={lotGalleryControlSession}
            enableEnlargedView={enableEnlargedView}
            showEnlargedView={showEnlargedView}
            onEnlargedView={setShowEnlargedView}
            imgClass="object-contain"
            hasBlurredEffect
          />
          {!showEnlargedView && (
            <PlayerControl
              galleryName="lotGallery"
              playerSession={lotGalleryControlSession?.playerControl}
              currentVideoPlayback={currentVideoPlayback}
              className="!absolute left-0"
            />
          )}
        </>
      ) : (
        <DeselectedView type={lastActiveOptionType || ''} />
      )}
    </>
  )
}
export default connect(({ projectIdentity }: RootStateFirebase) => ({
  projectIdentity,
}))(LotGallery)
