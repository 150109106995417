import React from 'react'
import { CSSTransition, SwitchTransition } from 'react-transition-group'

import { ChevronSvg } from '@components/adgroup-svg/react'

import { formatPrice } from '@utilities/helper'

import PropertyOption, { Option } from './property-option'

interface SelectPropertyProps {
  label: string
  options: Option[]
  value: string
  onChange: (value: string) => void
  canDeselect?: boolean
  placeholder?: string
  expandOptions: boolean
  onClick: () => void
  showAsThumbnail?: boolean
}

const SelectProperty = ({
  label,
  options,
  value,
  onChange,
  canDeselect,
  placeholder = 'Select',
  expandOptions,
  onClick,
  showAsThumbnail,
}: SelectPropertyProps) => {
  const selectedOption = React.useMemo(
    () => options.find((option) => option.id === value),
    [value, options]
  )
  const showOptions = React.useMemo(
    () => !!options.length && expandOptions,
    [expandOptions, options]
  )
  const [transitionState, setTransitionState] = React.useState<
    'enter' | 'exited'
  >('exited')

  const handleClick = React.useCallback(() => {
    if (!options.length) {
      return
    }
    onClick()
  }, [options, onClick])

  const handleChange = React.useCallback(
    (updatedValue: string) => () => {
      if (updatedValue === value) {
        if (canDeselect) {
          onChange('')
        }
        return
      }
      onChange(updatedValue)
    },
    [onChange, value, canDeselect]
  )

  const handleTransitionState = React.useCallback(
    (state: typeof transitionState) => () => {
      setTransitionState(state)
    },
    []
  )

  return (
    <div className="flex flex-col overflow-hidden first:pt-4 ipad-mini-land:first:pt-6">
      <div
        className="flex cursor-pointer items-center justify-between px-4 pb-1 ipad-mini-land:px-6"
        onClick={handleClick}
        role="none"
      >
        <div className="flex flex-col">
          <p className="counter-list-item text-base font-bold text-mainColour ipad-mini-land:text-lg">
            {label}
          </p>

          <SwitchTransition>
            <CSSTransition
              key={`${selectedOption?.id}_${selectedOption?.name}`}
              classNames="transition-lot-select-property-text-change"
              timeout={300}
            >
              <div className="mb-1">
                <p
                  className={`text-2xl font-semibold ${
                    selectedOption ? 'text-mainColour' : 'text-[#808080]'
                  }`}
                >
                  {selectedOption?.name || placeholder}
                </p>
                {!!selectedOption?.price && selectedOption?.price > 0 && (
                  <p className="text-sm font-semibold text-mainColour ipad-mini-land:text-base">
                    +{formatPrice(selectedOption?.price)}
                  </p>
                )}
              </div>
            </CSSTransition>
          </SwitchTransition>
        </div>
        {options.length ? (
          <ChevronSvg
            className="h-10 w-10 text-mainColour transition-all duration-500"
            rotate={showOptions ? 'top' : 'down'}
          />
        ) : null}
      </div>
      <CSSTransition
        in={showOptions}
        classNames="transition-lot-select-property-expand"
        timeout={500}
        onEnter={handleTransitionState('enter')}
        onExited={handleTransitionState('exited')}
      >
        <div
          className={`grid grid-cols-2 gap-x-3 ${
            showAsThumbnail ? 'gap-y-3 px-4 ipad-mini-land:px-6' : 'gap-y-2'
          }`}
        >
          {(!showOptions && transitionState === 'exited' ? [] : options).map(
            (option) => (
              <PropertyOption
                key={option.id}
                option={option}
                isSelected={value === option.id}
                onClick={handleChange(option.id)}
                showAsThumbnail={!!showAsThumbnail}
              />
            )
          )}
        </div>
      </CSSTransition>
    </div>
  )
}

export default SelectProperty
