import React from 'react'
import { connect } from 'react-redux'

import {
  ProjectIdentity,
  RootStateFirebase,
  SessionMap,
  UnitFilterInterface,
} from '@store/types'

import Container from '@components/container'
import DataHandler from '@components/data-handler'
import Filter from '@components/filter'
import { PrecinctOrLevelSkeleton } from '@components/skeleton'

import {
  Level,
  Unit,
  selectFromResult,
  useGetBuildingQuery,
} from '@api/building'
import {
  selectFromResult as selectFromInteractivePlanResult,
  useGetInteractivePlanQuery,
} from '@api/interactive-plan'

import FirebaseControlQuery from '@utilities/firebase-control-query'
import { generateId, getSession } from '@utilities/firebase-util'
import { filterUnit as filterUnitUtil } from '@utilities/unit-filter-util'

import { ChevronSvg, FunnelSvg } from '@svg/react'

import { notifyError } from '@adUtilities/notifier'

import BuildingList from './building-list'
import Control from './control'
import LevelList from './level-list'

interface PageProps {
  projectIdentity: ProjectIdentity
  session: SessionMap | undefined
}
const EnvisionVR = ({ projectIdentity, session }: PageProps) => {
  const firebaseControlQuery = FirebaseControlQuery({ projectIdentity })
  const [toggleFilter, setFilterToggle] = React.useState(false)
  const [singleBlockProject, setSingleBlockProject] = React.useState(false)
  const [unitFilter, setUnitFilter] = React.useState<UnitFilterInterface>()
  const [buildingData, setBuildingData] = React.useState<Array<string>>([])
  const [activeBuilding, setActiveBuilding] = React.useState('')
  const [levelData, setLevelData] = React.useState<Array<string>>([])
  const [rotateCamera, setRotateCamera] = React.useState(false)
  const [appliedAvailableUnitFilter, setAvailableUnitFilterApplyState] =
    React.useState(false)
  const [isLoaded, setLoadingState] = React.useState(false)

  const buildingPayload = useGetBuildingQuery(
    { projectName: projectIdentity.projectId },
    { selectFromResult }
  )
  const interactivePlanPayload = useGetInteractivePlanQuery(
    { projectName: projectIdentity.projectId },
    { selectFromResult: selectFromInteractivePlanResult }
  )

  const getActiveLevelAndBlocks = (
    levels: Array<Level>,
    activeBlock?: string
  ) => {
    const filteredLevels: Record<string, string[]> = {}
    const levelByBlock: Record<string, string[]> = {}

    levels.forEach((level: Level) => {
      const levelNumber = level.level
      const blockIds = level.data.map((item: Unit) => item.blockId)

      if (!filteredLevels[levelNumber]) {
        filteredLevels[levelNumber] = []
      }

      blockIds.forEach((blockId) => {
        if (blockId && !filteredLevels[levelNumber].includes(blockId)) {
          filteredLevels[levelNumber].push(blockId)
        }
      })
    })

    Object.keys(filteredLevels).forEach((level) => {
      const blockIds = filteredLevels[level]
      blockIds.forEach((blockId) => {
        if (!levelByBlock[blockId]) {
          levelByBlock[blockId] = []
        }
        levelByBlock[blockId].push(level)
      })
    })

    return activeBlock ? levelByBlock[activeBlock] : Object.keys(levelByBlock)
  }

  const handleBackButtonPress = async () => {
    setFilterToggle(false)
    if (activeBuilding === '') return

    await firebaseControlQuery.update({
      'envisionVR.activeBuilding': '',
    })
  }
  const handleToggleRotateCamera = async () => {
    await firebaseControlQuery.update({
      'envisionVR.rotateCamera': !rotateCamera,
    })
  }
  const handleRotateCameraBy = async (direction: 'left' | 'right') => {
    await firebaseControlQuery.update({
      'envisionVR.rotateCameraBy.direction': direction,
      'envisionVR.rotateCameraBy.triggerKey': generateId(10),
    })
  }
  const handleZoomCamera = async (action: 'in' | 'out') => {
    await firebaseControlQuery.update({
      'envisionVR.zoomCamera.action': action,
      'envisionVR.zoomCamera.triggerKey': generateId(10),
    })
  }
  const handleResetButtonClick = async () => {
    await firebaseControlQuery.update({
      'envisionVR.modelResetTriggerKey': generateId(10),
      'envisionVR.rotateCamera': false,
    })
  }
  const handleBuildingClick = async (arg: string) => {
    await firebaseControlQuery.update({
      'envisionVR.activeBuilding': arg,
      'envisionVR.isLoaded': false,
      'envisionVR.levels.data': [],
      'building.activeBlock': arg,
    })
  }
  const handleLevelClick = async (levelArg: string) => {
    const selectedLevel = buildingPayload.levels.find(
      (lvl: Level) => levelArg === lvl.level
    )
    if (!selectedLevel) {
      notifyError('Invalid level')
      return
    }
    const blockList = Object.keys(interactivePlanPayload.maps.blocks || {})
    let myBlockId = ''
    if (blockList.length > 1 && activeBuilding !== '') {
      const unit = selectedLevel.data.find(
        (unt: Unit) => unt.blockId === activeBuilding
      )
      if (!unit) {
        notifyError('Invalid block')
        return
      }
      myBlockId = unit.blockId
    }
    if (myBlockId !== '') {
      await firebaseControlQuery.update({
        [`building.activeBlock`]: myBlockId,
        [`building.activeLevel`]: levelArg,
      })
    } else {
      await firebaseControlQuery.updateCollection(
        'building.activeLevel',
        levelArg
      )
    }
    setTimeout(async () => {
      await firebaseControlQuery.updateRoute('building')
    }, 1000)
  }
  const getFilteredData = (levels: Level[]): Array<string> => {
    const filteredLevelList: Array<string> = []

    levels.forEach((level) => {
      if (!filteredLevelList.includes(level.level) && unitFilter) {
        level.data.some((unit) => {
          const shouldFilter =
            (activeBuilding === '' || unit.blockId === activeBuilding) &&
            filterUnitUtil(unit, unitFilter, projectIdentity.showPrice)

          if (shouldFilter) {
            filteredLevelList.push(level.level)
            return true
          }
          return false
        })
      }
    })
    return filteredLevelList
  }
  const levelsOptions = React.useMemo(
    () =>
      unitFilter?.apply
        ? getFilteredData(buildingPayload.levels)
            .map((item) => item)
            .filter((item) => levelData.includes(item))
        : levelData,
    [levelData, unitFilter?.apply, buildingPayload]
  )
  React.useEffect(() => {
    if (session) {
      const {
        building: { unitFilter: unitFilterFirebase },
        envisionVR: {
          isLoaded: isModelLoadedFirebase,
          rotateCamera: rotateCameraFirebase,
          buildings: { data: buildingDataFirebase },
          activeBuilding: activeBuildingFirebase,
          levels: { data: levelDataFirebase },
        },
      } = session
      setSingleBlockProject(buildingDataFirebase?.length === 1)
      setLoadingState(isModelLoadedFirebase)
      setUnitFilter(unitFilterFirebase)
      setRotateCamera(rotateCameraFirebase)
      setBuildingData(
        [...buildingDataFirebase].sort((a, b) => {
          const orderA = buildingPayload.blockOrders?.indexOf(a)
          const orderB = buildingPayload.blockOrders?.indexOf(b)

          if (orderA !== -1 && orderB !== -1) {
            return orderA - orderB
          }
          if (orderA !== -1) {
            return -1
          }
          return 1
        })
      )
      setActiveBuilding(activeBuildingFirebase)
      setLevelData(levelDataFirebase)
      setAvailableUnitFilterApplyState(
        Boolean(unitFilterFirebase.showAvailable && unitFilterFirebase.apply)
      )
    }
  }, [session, buildingPayload.isLoaded])
  React.useEffect(
    () => () => {
      firebaseControlQuery.update({
        'envisionVR.rotateCamera': false,
        'envisionVR.rotateCameraBy.direction': '',
        'envisionVR.rotateCameraBy.triggerKey': '',
        'envisionVR.zoomCamera.action': '',
        'envisionVR.zoomCamera.triggerKey': '',
        'envisionVR.modelResetTriggerKey': '',
      })
    },
    []
  )

  return (
    <Container>
      {!isLoaded && (
        <div className="absolute z-5 flex h-full w-full items-center justify-center bg-secondaryColour opacity-70">
          <div className="h-28 w-28 animate-spin rounded-full border-4 border-solid border-mainColour border-t-transparent text-center"></div>
        </div>
      )}
      <div className="h-full w-full pl-4">
        <div className="h-top-bar w-full items-end text-title font-medium text-secondaryColour">
          <div
            className={`py-1 ${
              activeBuilding === '' ? 'invisible' : 'visible'
            }`}
          >
            <button
              onClick={handleBackButtonPress}
              type="button"
              className="relative flex items-center text-default font-normal text-secondaryColour"
            >
              <ChevronSvg className="absolute -left-2.5 h-8 w-8" />

              <span className="ml-6">Buildings</span>
            </button>
          </div>
          <div className="flex w-full items-baseline justify-between text-secondaryColour">
            <div className="text-title font-medium">
              {activeBuilding !== '' || singleBlockProject
                ? activeBuilding
                : 'Buildings'}
            </div>
            {!projectIdentity.hideFilter &&
              (activeBuilding !== '' || singleBlockProject) && (
                <button
                  onClick={() => setFilterToggle(true)}
                  type="button"
                  className="mr-4 inline-flex items-center text-default"
                >
                  <span className="mr-1">Filters</span>
                  <FunnelSvg className="h-6 w-6" />
                </button>
              )}
          </div>
        </div>

        <DataHandler
          payload={{
            ...buildingPayload,
            data: buildingPayload.levels,
          }}
          skeletonFrame={<PrecinctOrLevelSkeleton />}
        >
          <div className="h-page-body w-full">
            <div className="flex">
              <div className="no-scrollbar h-page-body w-[60%] overflow-auto">
                {activeBuilding !== '' || singleBlockProject ? (
                  <LevelList
                    isLoaded={isLoaded}
                    levels={levelsOptions}
                    handleClick={handleLevelClick}
                    buildingLevels={buildingPayload.levels}
                    onlyShowAvailable={appliedAvailableUnitFilter}
                    activeBuilding={activeBuilding}
                    singleBlockProject={singleBlockProject}
                    envisionActiveLevels={getActiveLevelAndBlocks(
                      buildingPayload.levels,
                      activeBuilding
                    )}
                    showPrice={projectIdentity.showPrice}
                    unitFilter={session?.building.unitFilter}
                  />
                ) : (
                  <BuildingList
                    isLoaded={isLoaded}
                    envisionActiveBlocks={getActiveLevelAndBlocks(
                      buildingPayload.levels
                    )}
                    buildings={buildingData}
                    handleClick={handleBuildingClick}
                  />
                )}
              </div>
              <div className="ml-4 w-[40%]">
                <Control
                  isLoading={false}
                  rotateCamera={rotateCamera}
                  handleToggleRotateCamera={handleToggleRotateCamera}
                  handleZoomCamera={handleZoomCamera}
                  handleRotateCameraBy={handleRotateCameraBy}
                  handleResetButtonClick={handleResetButtonClick}
                />
              </div>
            </div>
          </div>
          {session && (
            <Filter
              toggle={setFilterToggle}
              isOpen={toggleFilter}
              firebaseUnitFilter={session.building.unitFilter}
              firebaseActiveBlock={session.building.activeBlock}
              firebaseActiveLevel={session.building.activeLevel}
              hideLevel
              isEnvisionFilter={!singleBlockProject}
            />
          )}
        </DataHandler>
      </div>
    </Container>
  )
}
export default connect(({ projectIdentity, firestore }: RootStateFirebase) => ({
  session: getSession(firestore),
  projectIdentity,
}))(EnvisionVR)
