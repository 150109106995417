import React from 'react'

import {
  GalleryControlInterface,
  GalleryInterface,
  GalleryItemInterface,
  PlayerControlInterface,
  ProjectIdentity,
} from '@store/types'

import CarouselHandler from '@components/carousel-handler'
import PlayerControl from '@components/player-control'

import { Unit, ViewLineImage } from '@api/building'
import {
  UnitGalleryInterface,
  selectFromResult as selectFromUnitGalleryResult,
  useGetUnitGalleryQuery,
} from '@api/unit-gallery'

export interface UnitGalleryControlSessionInterface {
  galleryControl: GalleryControlInterface
  playerControl: PlayerControlInterface
}
interface UnitGalleryProps {
  projectIdentity: ProjectIdentity
  unit: Unit
  unitGalleryControlSession: UnitGalleryControlSessionInterface | undefined
  getUnitGalleryItemLength: (unitGalleryItemLength: number) => void
  onlyShowUnitGalleries: boolean
  hasBlurredEffect?: boolean
  imgClass?: string
}

const NO_IMAGE_OBJECT: GalleryItemInterface = {
  id: 'no-image',
  label: 'No Image',
  imageSource: '',
}

const UnitGallery = ({
  projectIdentity,
  unit,
  unitGalleryControlSession,
  getUnitGalleryItemLength,
  onlyShowUnitGalleries,
  hasBlurredEffect,
  imgClass,
}: UnitGalleryProps) => {
  const BUCKET_URL = process.env.REACT_APP_BUCKET_LEGACY
  const [unitGallery, setUnitGallery] = React.useState<Array<GalleryInterface>>(
    []
  )
  const [currentVideoPlayback] = React.useState<string>('')

  const [showEnlargedView, setShowEnlargedView] = React.useState(false)

  const unitGalleryPayload = useGetUnitGalleryQuery(
    { projectName: projectIdentity.projectId, unitId: unit.id },
    { selectFromResult: selectFromUnitGalleryResult }
  )

  const { unitGalleryData, isLoaded, isFetching } = unitGalleryPayload

  const buildGallery = () => {
    const projectIdHash = projectIdentity.projectUUID
    const galleryItems: Array<GalleryItemInterface> = []

    const {
      metas: { floorImage, viewLineImages, floorPdf },
    } = unit

    if (!onlyShowUnitGalleries && viewLineImages && viewLineImages.length > 0) {
      viewLineImages.forEach((viewLineImage: ViewLineImage) => {
        galleryItems.push({
          id: viewLineImage.id,
          imageSource: `${BUCKET_URL}/${projectIdHash}/views/${viewLineImage.src}`,
        })
      })
    }

    if (!onlyShowUnitGalleries && floorImage) {
      galleryItems.unshift({
        id: floorPdf,
        imageSource: `${BUCKET_URL}/${projectIdHash}/floorplans/preview/${floorImage}`,
      })
    }

    if (unitGalleryData.length > 0) {
      unitGalleryData.forEach((galleryItem: UnitGalleryInterface) => {
        const imageSource =
          galleryItem.type === 'video'
            ? galleryItem.thumbnail || ''
            : galleryItem.src
        const videoSource = galleryItem.type === 'video' ? galleryItem.src : ''
        galleryItems.push({ id: galleryItem.id, imageSource, videoSource })
      })
    }

    getUnitGalleryItemLength(galleryItems.length)

    if (!galleryItems.length) {
      setUnitGallery([{ items: [NO_IMAGE_OBJECT] }])
      return
    }

    if (galleryItems.length > 0) {
      setUnitGallery([{ items: galleryItems }])
    }
  }

  React.useEffect(() => {
    buildGallery()
  }, [unit])

  React.useEffect(() => {
    if (unitGalleryData.length > 0) {
      buildGallery()
    }
  }, [unitGalleryData])

  const enableEnlargedView = React.useMemo(
    () =>
      (unitGallery[0]?.items || []).filter(
        (item) => item.id !== NO_IMAGE_OBJECT.id
      ).length >= 1,
    [unitGallery[0]?.items]
  )

  if (isFetching) {
    return (
      <div className="cursor-hand h-full w-full rounded-lg bg-slate-700/30 text-heading font-medium drop-shadow-lg">
        <div className="shimmer-container relative h-full opacity-50"></div>
      </div>
    )
  }

  return (
    <>
      {unitGallery.length > 0 ? (
        <>
          <CarouselHandler
            carouselName="unitGallery"
            carousel={{
              label: '',
              items: unitGallery[0].items,
            }}
            carouselSession={unitGalleryControlSession}
            enableEnlargedView={enableEnlargedView}
            showEnlargedView={showEnlargedView}
            onEnlargedView={setShowEnlargedView}
            imgClass={imgClass}
            hasBlurredEffect={hasBlurredEffect}
          />
          {!showEnlargedView && (
            <PlayerControl
              galleryName="unitGallery"
              playerSession={unitGalleryControlSession?.playerControl}
              currentVideoPlayback={currentVideoPlayback}
              className="!absolute left-0"
            />
          )}
        </>
      ) : (
        <>
          {hasBlurredEffect && isLoaded && (
            <div className="flex h-full w-full items-center justify-center rounded-lg bg-secondaryColour text-mainColour">
              No gallery found
            </div>
          )}
        </>
      )}
    </>
  )
}
export default UnitGallery
