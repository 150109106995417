import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import {
  InteractivePlanType,
  ProjectIdentity,
  RootStateFirebase,
  SessionMap,
} from '@store/types'

import Container from '@components/container'
import DataHandler from '@components/data-handler'
import FILTER_INITIAL_STATE_HOUSE_AND_LAND from '@components/filter/filterStateHouseAndLand'
import PrecinctCard from '@components/precinct-card'
import { AreaSkeleton } from '@components/skeleton'

import {
  selectPrecinctListFromResult,
  useGetPrecinctListQuery,
} from '@api/houseAndLand'
import {
  Polygon,
  selectFromResult as selectInteractiveFromResult,
  useGetInteractivePlanQuery,
} from '@api/interactive-plan'

import FirebaseControlQuery from '@utilities/firebase-control-query'
import { getSession } from '@utilities/firebase-util'

export interface AreaViewHouseAndLandProps {
  projectIdentity: ProjectIdentity
  session: SessionMap | undefined
}

const AreaViewHouseAndLand = ({
  projectIdentity,
  session,
}: AreaViewHouseAndLandProps) => {
  const firebaseControlQuery = FirebaseControlQuery({ projectIdentity })

  const [areaViews, setAreaViews] = useState<Polygon[]>([])

  const interactivePayload = useGetInteractivePlanQuery(
    {
      projectName: projectIdentity.projectId,
      type: InteractivePlanType.AreaView,
    },
    { selectFromResult: selectInteractiveFromResult }
  )
  const precinctsPayload = useGetPrecinctListQuery(
    { projectName: projectIdentity.projectId },
    {
      selectFromResult: selectPrecinctListFromResult,
    }
  )

  const updateTriggerBuilding = (state: boolean) =>
    firebaseControlQuery.updateCollection('areaView.triggerBuilding', state)

  const handleMapClick = async (groupId: string) => {
    await firebaseControlQuery.updateCollection(
      'houseAndLand.activePrecinctId',
      groupId
    )

    await updateTriggerBuilding(true)
  }

  const resetHouseAndLandMap = () =>
    firebaseControlQuery.update({
      [`houseAndLand.activePrecinctId`]: '',
      [`houseAndLand.activeStageId`]: '',
      [`houseAndLand.activeLotId`]: '',
    })

  const resetHouseAndLandView = () =>
    firebaseControlQuery.update({
      [`building.sidePanelFolded`]: true,
      [`houseAndLand.lotFilter`]: FILTER_INITIAL_STATE_HOUSE_AND_LAND,
    })

  useEffect(() => {
    const { maps, isLoaded, isError } = interactivePayload
    const { precinctList, isLoaded: isPrecinctListLoaded } = precinctsPayload

    const isLoadedSuccessfully = isLoaded && !isError
    const hasMaps = Object.keys(maps).length > 0
    const isAreaViewsEmpty = areaViews.length === 0
    const hasValidPolygons = maps.areaView?.polygons?.length > 0

    if (
      isLoadedSuccessfully &&
      hasMaps &&
      isAreaViewsEmpty &&
      hasValidPolygons &&
      isPrecinctListLoaded
    ) {
      setAreaViews(
        maps.areaView.polygons
          .map((res: Polygon) => res)
          ?.sort((a, b) => {
            const aIndex = precinctList.findIndex(
              (item) => item.label === a.groupId
            )
            const bIndex = precinctList.findIndex(
              (item) => item.label === b.groupId
            )
            if (aIndex === -1) {
              return 1
            }
            if (bIndex === -1) {
              return -1
            }
            return aIndex - bIndex
          })
      )
    }
  }, [interactivePayload.isLoaded, areaViews, precinctsPayload.isLoaded])

  useEffect(() => {
    if (!session) {
      return
    }

    const {
      areaView: { triggerBuilding },
      houseAndLand: { activeStageId, activeLotId },
    } = session

    if (activeStageId || activeLotId) {
      resetHouseAndLandMap().catch((err) => console.error(err))
    }

    if (!triggerBuilding) {
      return
    }

    setTimeout(async () => {
      await updateTriggerBuilding(false)
      await resetHouseAndLandView()
      await firebaseControlQuery.updateRoute('precinct')
    }, 1000)
  }, [
    session?.areaView.triggerBuilding,
    session?.houseAndLand.activeStageId,
    session?.houseAndLand.activeLotId,
  ])

  return (
    <Container>
      <DataHandler
        payload={{
          ...interactivePayload,
          data: areaViews || [],
        }}
        skeletonFrame={<AreaSkeleton />}
      >
        <div className="h-full w-full px-4">
          <div className="flex h-top-bar w-full items-end text-title font-medium text-neutralColour">
            <div className="pb-5">Precincts</div>
          </div>
          <div className="no-scrollbar h-page-body w-full overflow-auto">
            {areaViews.map((res) => (
              <PrecinctCard
                key={res.groupId}
                label={res.label || res.groupId}
                handleClick={() => handleMapClick(res.groupId)}
              />
            ))}
          </div>
        </div>
      </DataHandler>
    </Container>
  )
}

export default connect(({ projectIdentity, firestore }: RootStateFirebase) => ({
  session: getSession(firestore),
  projectIdentity,
}))(AreaViewHouseAndLand)
