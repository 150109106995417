import React from 'react'

export interface NoDataFoundProps {
  message?: string
}

const DataNotFound = ({ message = 'No data found.' }: NoDataFoundProps) => (
  <div className="flex h-full w-full flex-col justify-center">
    <div className="rounded-full bg-secondaryColour p-16 text-center text-title">
      {message}
    </div>
  </div>
)

export default DataNotFound
