import { Routes } from '@store/enums'

function numberWithCommas(num: string | number): string {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

function getQueryStringParams(query: string) {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
        .split('&')
        .reduce((params: any, param: any) => {
          const newParams = params
          const [key, value] = param.split('=')
          newParams[key] = value
            ? decodeURIComponent(value.replace(/\+/g, ' '))
            : ''
          return newParams
        }, {})
    : {}
}

const getFirebaseHost = (): string => {
  const appConfig = localStorage.getItem('appConfig')

  if (!appConfig) {
    return 'localhost'
  }

  const appConfigObject = JSON.parse(appConfig)

  return appConfigObject.firebaseHost || 'localhost'
}

const getFirebasePort = (): string => {
  const appConfig = localStorage.getItem('appConfig')

  if (!appConfig) {
    return ''
  }

  const appConfigObject = JSON.parse(appConfig)

  return appConfigObject.firebasePort || ''
}

const getAssetHost = (): string => {
  const appConfig = localStorage.getItem('appConfig')

  if (!appConfig) {
    return 'localhost'
  }

  const appConfigObject = JSON.parse(appConfig)

  return appConfigObject.assetHost || 'localhost'
}

const getAssetPort = (): string => {
  const appConfig = localStorage.getItem('appConfig')

  if (!appConfig) {
    return ''
  }

  const appConfigObject = JSON.parse(appConfig)

  return appConfigObject.assetPort || ''
}

const getActiveProjectId = (): string => {
  const projectIdentity = localStorage.getItem('projectIdentity')
  if (!projectIdentity) {
    return ''
  }
  const projectIdentityObj = JSON.parse(projectIdentity)
  return projectIdentityObj.projectId || ''
}

const roomQueryString = (): string => {
  const appConfig = localStorage.getItem('appConfig')

  if (!appConfig) return ''

  const appConfigObject = JSON.parse(appConfig)

  return appConfigObject.room ? `?showcaseRoom=${appConfigObject.room}` : ''
}

const getFileNameFromPathOrURL = (str: string) => {
  const fileName = str.split('/').pop()?.split('.')
  if (fileName) {
    fileName.pop()
    return fileName.join('.')
  }
  return ''
}

const isValidSessionId = (value: string): boolean =>
  !Number.isNaN(value) && /^\d{6}$/.test(value)

const capitalizeFirstLetter = (string: string) => {
  const text = string.toLowerCase()
  return text.charAt(0).toUpperCase() + text.slice(1)
}

const isNotSessionSettingsOrTools = (pathname: string): boolean =>
  pathname !== `/${Routes.SESSION_SETTINGS}` && pathname !== `/${Routes.TOOLS}`

const formatPrice = (value: string | number) =>
  !Number.isNaN(Number(value))
    ? `$${Number(value).toLocaleString('en-US')}`
    : ''

export {
  roomQueryString,
  numberWithCommas,
  getQueryStringParams,
  getFirebaseHost,
  getFirebasePort,
  getAssetHost,
  getAssetPort,
  getFileNameFromPathOrURL,
  isValidSessionId,
  getActiveProjectId,
  capitalizeFirstLetter,
  isNotSessionSettingsOrTools,
  formatPrice,
}
