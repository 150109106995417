import React from 'react'

interface ComponentPropsInterface {
  label: string
  lotCount: number
  handleClick: () => void
  onlyShowAvailable: boolean
  isDisabled?: boolean
}

const StageCard = ({
  label,
  handleClick,
  lotCount,
  onlyShowAvailable,
  isDisabled,
}: ComponentPropsInterface) => (
  <button
    onClick={handleClick}
    type="button"
    disabled={isDisabled}
    className={`cursor-hand flex w-full items-center justify-between rounded-lg bg-gradient px-[30px] shadow-standard ${
      onlyShowAvailable ? 'py-7' : 'py-10'
    } ${isDisabled ? 'opacity-60' : ''} text-neutralColour`}
  >
    <span className="text-heading font-medium">Stage {label}</span>
    <span className="flex w-40 flex-col text-right text-subHeading font-normal">
      <span>
        {lotCount} {lotCount > 1 ? 'lots' : 'lot'}
      </span>
      <span className="text-default">{onlyShowAvailable && 'available'}</span>
    </span>
  </button>
)

export default StageCard
