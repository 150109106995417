import { CarouselProvider, Slide, Slider } from 'pure-react-carousel'
import React from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
  InteractiveMapListInterface,
  ProjectIdentity,
  RootStateFirebase,
  TabInterface,
} from '@store/types'

import {
  InteractiveMapItem,
  InteractiveMapTab,
} from '@components/interactive-map-control/elements'

import FirebaseControlQuery from '@utilities/firebase-control-query'
import { isNotSessionSettingsOrTools } from '@utilities/helper'

import useScrollEffect from '@hooks/useScrollEffect'

export interface InteractiveMapHandlerInterface {
  interactiveMapList: Array<InteractiveMapListInterface>
  interactiveMapSession: string[]
  interactiveMapActiveCategory:
    | { label: string; baseImageOverride: string }
    | undefined
  projectIdentity: ProjectIdentity
  storyMode: boolean
}

interface InteractiveMapTabInterface extends TabInterface {
  activeItem: number
}

const InteractiveMapHandler = ({
  interactiveMapList,
  interactiveMapSession,
  interactiveMapActiveCategory,
  projectIdentity,
  storyMode,
}: InteractiveMapHandlerInterface) => {
  const history = useHistory()

  const firebaseControlQuery = FirebaseControlQuery({ projectIdentity })
  const [activeTabIndex, setActiveTabIndex] = React.useState(0)
  const [tabs, setTabs] = React.useState<Array<InteractiveMapTabInterface>>([])
  const hasEffectRanOnceRef = React.useRef(false)

  const isItemActives = (groupId: string): boolean =>
    interactiveMapSession &&
    interactiveMapSession.length > 0 &&
    interactiveMapSession.includes(groupId)

  const buildTab = () => {
    const filterTabs: InteractiveMapTabInterface[] = []

    interactiveMapList.forEach(
      (item: InteractiveMapListInterface, index: number) => {
        if (item.label && item.label !== '') {
          const groupIDS = [...interactiveMapSession]

          const activeItem = item.items.filter((layer) =>
            groupIDS.includes(layer.groupId)
          ).length

          filterTabs.push({ index, label: item.label, activeItem })
        }
      }
    )
    setTabs(filterTabs)
  }

  const updateFirebaseActiveCategory = async (
    label: string,
    baseImageOverride: string,
    clearInteractiveMap: boolean
  ) => {
    try {
      const payload = clearInteractiveMap
        ? {
            interactiveMap: [],
            interactiveMapActiveCategory: { label, baseImageOverride },
          }
        : { interactiveMapActiveCategory: { label, baseImageOverride } }

      await firebaseControlQuery.update(payload)
    } catch {
      throw new Error('Failed to update Firebase active category')
    }
  }

  const setCategoryBackgroundInShowcase = async (
    categoryLabel: string,
    clearInteractiveMap = true
  ) => {
    const category = interactiveMapList.find(
      (interactiveMap) => interactiveMap.label === categoryLabel
    )

    if (!category) {
      return
    }

    const hasVisibleLayer = category.items.some((item) => item.visible)

    if (hasVisibleLayer) {
      return
    }

    const hasCategoryDefault = category.items.find(
      (item) => item.categoryDefault && item.baseImageOverride
    )

    const baseImage = hasCategoryDefault
      ? hasCategoryDefault.baseImageOverride
      : ''

    const clearMap = !storyMode ? false : clearInteractiveMap

    await updateFirebaseActiveCategory(categoryLabel, baseImage, clearMap)
  }

  const handleTabChange = (index: number, label: string) => {
    if (activeTabIndex === index) {
      return
    }

    setActiveTabIndex(index)
    setCategoryBackgroundInShowcase(label).catch((err) => console.error(err))
  }

  const handleItemClick = async (groupId: string, index: number) => {
    let groupIDS = [...interactiveMapSession]
    if (groupIDS.includes(groupId)) {
      groupIDS = groupIDS.filter((item) => item !== groupId)
    } else {
      groupIDS = [...groupIDS, groupId]
    }
    if (storyMode) {
      groupIDS = [
        ...groupIDS.filter((i) =>
          interactiveMapList[index].items.find((j) => j.groupId === i)
        ),
      ]
    }
    await firebaseControlQuery.update({
      interactiveMap: groupIDS,
    })
  }

  useScrollEffect(activeTabIndex)

  React.useEffect(() => {
    if (interactiveMapActiveCategory) {
      const { label } = interactiveMapActiveCategory
      const categoryIndex = interactiveMapList.findIndex(
        (item) => item.label === label
      )

      const indexToSet = categoryIndex === -1 ? 0 : categoryIndex
      setActiveTabIndex(indexToSet)
    }
  }, [interactiveMapActiveCategory?.label, interactiveMapList])

  React.useEffect(() => {
    const timer = setTimeout(() => {
      const slider = document.querySelector('.slider') as HTMLDivElement
      const translateValue = `${-activeTabIndex * 100}%`
      if (slider) {
        slider.style.transform = `translateX(${translateValue})`
      }
    }, 100)
    return () => clearTimeout(timer)
  }, [activeTabIndex])

  React.useEffect(() => {
    buildTab()
  }, [interactiveMapList, interactiveMapSession])

  React.useEffect(() => {
    if (!hasEffectRanOnceRef.current && tabs && tabs.length) {
      const activeCategoryLabel = tabs.find(
        (item) => item.index === activeTabIndex
      )?.label

      if (!activeCategoryLabel) {
        return
      }

      const clearInteractiveMap = !interactiveMapSession.length

      setCategoryBackgroundInShowcase(
        activeCategoryLabel,
        clearInteractiveMap
      ).catch((err) => console.error(err))

      hasEffectRanOnceRef.current = true
    }
  }, [tabs, activeTabIndex, interactiveMapSession])

  React.useEffect(() => {
    const handleRouteChange = async (location: Location) => {
      const { pathname } = location
      if (isNotSessionSettingsOrTools(pathname)) {
        await updateFirebaseActiveCategory('', '', false)
      }
    }

    const unsubscribeHistory = history.listen(handleRouteChange)

    return () => {
      unsubscribeHistory()
    }
  }, [history])

  return (
    <div className="h-full w-full">
      <CarouselProvider
        naturalSlideWidth={125}
        naturalSlideHeight={125}
        dragEnabled={false}
        disableKeyboard
        lockOnWindowScroll
        touchEnabled={false}
        totalSlides={interactiveMapList.length}
        className="flex h-full w-full flex-col"
        isIntrinsicHeight
        currentSlide={activeTabIndex}
      >
        {tabs.length > 1 && (
          <InteractiveMapTab
            tabs={tabs}
            activeTabIndex={activeTabIndex}
            onTabChange={handleTabChange}
          />
        )}

        <Slider
          className="flex-1"
          classNameTray="h-full"
          classNameTrayWrap="h-full"
        >
          {interactiveMapList.map((gallery, galleryIndex) => (
            <Slide
              key={gallery.label}
              index={galleryIndex}
              className="no-scrollbar overflow-y-auto"
            >
              <div className="flex flex-col gap-[15px] px-5">
                {gallery.items?.map((itemData, index) => (
                  <InteractiveMapItem
                    key={`interactive-map-item-${index.toString()}`}
                    itemData={itemData}
                    onItemClick={(groupId) =>
                      handleItemClick(groupId, galleryIndex)
                    }
                    isActive={isItemActives(itemData.groupId)}
                  />
                ))}
              </div>
            </Slide>
          ))}
        </Slider>
      </CarouselProvider>
    </div>
  )
}

export default connect(({ projectIdentity }: RootStateFirebase) => ({
  projectIdentity,
}))(InteractiveMapHandler)
