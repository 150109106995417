import React from 'react'

import { PackageConfigurationInterface } from '@store/types'

import { LotInterface, PackageInterface } from '@api/houseAndLand'

interface ParseLotPackageConfigurationArgs {
  configuration?: PackageConfigurationInterface
  lot: LotInterface
  package?: PackageInterface
}

const useParseLotPackageConfiguration = () => {
  const parser = React.useCallback(
    ({
      configuration,
      lot,
      package: lotPackage,
    }: ParseLotPackageConfigurationArgs) => {
      const matchedFloorplanOption = lotPackage?.floorplanOptions?.find(
        (i) => i.id === configuration?.floorplanOption
      )
      const matchedFacade = lotPackage?.facades?.find(
        (i) => i.id === configuration?.facade
      )
      const matchedFacadeColour = matchedFacade?.facadeColors?.find(
        (i) => i.id === configuration?.facadeColor
      )
      const matchedInternalTheme = lotPackage?.internalThemes?.find(
        (i) => i.id === configuration?.internalTheme
      )
      const matchedInternalColourSchema =
        matchedInternalTheme?.internalColorSchemes?.find(
          (i) => i.id === configuration?.internalColorScheme
        )

      const titles: string[] = lotPackage
        ? [
            lotPackage.name,
            ...(matchedFloorplanOption ? [matchedFloorplanOption.name] : []),
            ...(matchedFacade ? [matchedFacade.name] : []),
            ...(matchedFacadeColour ? [matchedFacadeColour.name] : []),
            ...(matchedInternalTheme ? [matchedInternalTheme.name] : []),
            ...(matchedInternalColourSchema
              ? [matchedInternalColourSchema.name]
              : []),
          ]
        : []

      const price =
        (lot.price || 0) +
          (matchedFloorplanOption?.price || 0) +
          (matchedFacade?.price || 0) +
          (matchedFacadeColour?.price || 0) +
          (matchedInternalTheme?.price || 0) +
          (matchedInternalColourSchema?.price || 0) || lot.price

      return {
        configurationTitle: titles.join(', '),
        photo: lotPackage?.galleries?.[0]?.url || '',
        price,
      }
    },
    []
  )

  return [parser] as const
}

export default useParseLotPackageConfiguration
