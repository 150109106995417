import React, { MouseEventHandler } from 'react'
import { connect } from 'react-redux'

import {
  InteractiveMapItemInterface,
  ProjectIdentity,
  RootStateFirebase,
} from '@store/types'

import FirebaseControlQuery from '@utilities/firebase-control-query'

import { ArrowSvg, EyeCloseSvg, EyeSvg } from '@svg/react'

import useParseMapLayerActionRoute from '@adUtilities/map-action-route-util'

export interface ComponentPropsInterface {
  projectIdentity: ProjectIdentity
  itemData: InteractiveMapItemInterface
  onItemClick: (groupId: string) => void
  isActive: boolean
}

const InteractiveMapItem = ({
  projectIdentity,
  itemData,
  onItemClick,
  isActive,
}: ComponentPropsInterface) => {
  const { actionRoute } = itemData
  const firebaseControlQuery = FirebaseControlQuery({ projectIdentity })
  const [parseMapActionRoute] = useParseMapLayerActionRoute()
  const { path, params, isValid } = React.useMemo(
    () => parseMapActionRoute(actionRoute),
    [actionRoute, parseMapActionRoute]
  )

  const handleClick = React.useCallback<MouseEventHandler<HTMLAnchorElement>>(
    async (e) => {
      e.preventDefault()
      e.stopPropagation()
      if (path === 'level-view') {
        await firebaseControlQuery.update({
          [`building.activeBlock`]: params.block || '',
        })
      }
      if (path === 'building') {
        await firebaseControlQuery.update({
          [`building.activeBlock`]: params.block || '',
          [`building.activeLevel`]: params.level || '',
          [`building.activeUnit`]: params.unit || '',
        })
      }
      if (path) {
        firebaseControlQuery.updateRoute(path)
      }
    },
    [path, params]
  )

  return (
    <button
      type="button"
      className={`flex cursor-pointer items-center justify-between rounded-lg bg-secondaryColour p-5 text-mainColour transition-all duration-300 ${
        isActive ? 'border-l-8 border-mainColour ' : ''
      }`}
      onClick={() => {
        onItemClick(itemData.groupId)
      }}
    >
      {isValid ? (
        <a href={path} onClick={handleClick}>
          <span className="border-b-2 border-mainColour text-default font-medium">
            {itemData.label}
          </span>
          <span className="border-b-2 border-mainColour text-default font-medium">
            <ArrowSvg
              size="m"
              className="-mr-2.5 inline-block h-10 w-10 stroke-mainColour"
              styles={{ rotate: '135deg' }}
            />
          </span>
        </a>
      ) : (
        <h2 className="text-default font-medium">{itemData.label}</h2>
      )}
      <span
        className={`rounded-full  ${
          isActive ? 'bg-mainColour' : 'bg-neutralColour'
        } px-2 py-2 transition-all duration-300`}
      >
        {isActive ? (
          <EyeCloseSvg className="h-7 w-7 text-neutralColour " />
        ) : (
          <EyeSvg className="h-7 w-7 text-mainColour" />
        )}
      </span>
    </button>
  )
}

export default connect(({ projectIdentity }: RootStateFirebase) => ({
  projectIdentity,
}))(InteractiveMapItem)
